@import url(https://fonts.googleapis.com/css2?family=Exo:wght@200;500&family=Oxygen:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --circlePrime: #53d8eb;
  --circleSecond: #4a84f6;
  --circleThird: #4af6ad;
  --primeColor: #53d8eb;
  --secondColor: #4a84f6;
  --thirdColor: #3f99d4;
  --lightGrey: rgb(228, 227, 227);
  --projectImgWidth: 270px;
  --projectImgHeight: 270px;
  --catImgWidth: 200px;
  --catImgHeight: 200px;

  --myGradient: linear-gradient(
    to left,
    var(--secondColor),
    var(--primeColor),
    var(--thirdColor)
  );

  --sectionPaddingTop: 1%;
}

body {
  font-family: "Exo", sans-serif;
}

.App {
  text-align: center;
  align-items: center;
}

#landingPageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.child {
  height: 100px;
  width: 100px;
}

#svgContainer {
  width: 100vw;
  height: 100vh;
}

#titleTextContainer {
  margin-top: -65vh;
  z-index: 1;
  background-color: white;
  padding: 1%;
  border: 2px solid rgb(228, 227, 227);
  border: 2px solid var(--lightGrey);
  border-radius: 20px;
  transition: all 1s;
}

#titleTextContainer:hover {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    4px 8px 4px rgba(0, 0, 0, 0.09);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 900ms;
}

#landingTextData {
  background: linear-gradient(
    to left,
    #4a84f6,
    #53d8eb,
    #3f99d4);
  background: var(--myGradient);
  font-size: 50px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 300%;
  background-position: right;
  -webkit-animation: colorShift 5s infinite alternate;
          animation: colorShift 5s infinite alternate;
}

@-webkit-keyframes colorShift {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}

@keyframes colorShift {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}

.circle {
  -webkit-animation: circleMove 10s infinite alternate;
          animation: circleMove 10s infinite alternate;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  transition: all 500ms;
}

.circle_primary:hover {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  fill: #53d8eb;
  fill: var(--circlePrime);
  transition: all 700ms;
}

.circle_secondary:hover {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  fill: #4a84f6;
  fill: var(--circleSecond);
  transition: all 700ms;
}

.circle_third:hover {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  fill: #4af6ad;
  fill: var(--circleThird);
  transition: all 700ms;
}

#headerContainer {
  /* background-color: var(--primeColor); */
  background-color: white;
  border: 1px solid #53d8eb;
  border: 1px solid var(--primeColor);
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 0.2em;
  gap: 0.2em;
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.2s;
  /* padding-bottom: 0.5em; */
  height: 3em;
  z-index: 2;
}

.headerLink {
  font-size: 20px;
  margin-right: 2em;
  padding: 0.5%;
  cursor: pointer;
  font-family: "Oxygen", sans-serif;
}

.headerLink:hover {
  border-bottom: 5px solid black;
  font-weight: bold;
  /* transition: all 500ms; */
  transition: border-bottom 300ms;
}

/* portfolio section  */

#portfolioSection {
  background-color: rgb(228, 227, 227);
  background-color: var(--lightGrey);
  padding-bottom: 5%;
  padding-top: 1%;
  padding-top: var(--sectionPaddingTop);
  /* filter: blur(5px); */
}

#portfolioTitle {
  margin-top: 1.5%;
}

#projectsLive {
  margin-top: -40px;
  font-size: smaller;
}

#portfolioRowsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: purple; */
  width: 100vw;
}

#projectsRowJs {
  /* background-color: red; */
  width: 50vw;
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.projectTitle {
  font-size: 17px;
}

.rowName {
  font-size: 1.7em;
  margin-top: 20px;
}

/* .rowName:last-child {
  margin-top: 15px;
} */

#projectsRowPython {
  /* background-color: green; */
  width: 75vw;
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.imgContainerProject {
  min-width: 270px;
  min-width: var(--projectImgWidth);
  max-width: 270px;
  max-width: var(--projectImgWidth);
  min-height: 270px;
  min-height: var(--projectImgHeight);
  max-height: 270px;
  max-height: var(--projectImgHeight);
  position: relative;
  display: inline-block;
  margin: 10px;
  transition: all 500ms;
  border-radius: 20px;
}

.imgContainerProject:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);

  transition: all 500ms;
}

.projectImg {
  width: 270px;
  width: var(--projectImgWidth);
  height: 270px;
  height: var(--projectImgHeight);
  position: relative;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  border-radius: 20px;
}

.hiddenProjectBox {
  display: none;
  padding: 5px;
  min-width: 270px;
  min-width: var(--projectImgWidth);
  max-width: 270px;
  max-width: var(--projectImgWidth);
  min-height: 270px;
  min-height: var(--projectImgHeight);
  max-height: 270px;
  max-height: var(--projectImgHeight);
  border-radius: 20px;
  /* background-color: rgba(0, 0, 0, 0.85); */
}

.imgContainerProject:hover .hiddenProjectBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 17px;
  background-color: #4a84f6;
  background-color: var(--secondColor);
  opacity: 0.9;
  border: 4px solid white;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    4px 8px 4px rgba(0, 0, 0, 0.09);
}

img {
  vertical-align: top;
}

/* SKILLS SECTION  */

#skillsSection {
  padding-top: 1%;
  padding-top: var(--sectionPaddingTop);
  padding-bottom: 5%;
  margin-bottom: 2%;
}

#skillCardsContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  justify-content: space-around;
  align-items: flex-start;
}

.skillCard {
  max-width: 30vw;
}

.skillIconContainer {
  transition: all 600ms;
  /* display: inline-block; */
  /* width: 20vw; */
  /* background-color: blue; */
}
.skillIconContainer:hover {
  fill: #4a84f6;
  fill: var(--secondColor);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 600ms;
}

.skillTitle {
  margin-top: 5%;
  font-size: 2.2em;
}

.skillList {
  display: inline-block;
  text-align: left;
}

.skillListItem {
  font-size: 1.1em;
  margin-top: 1%;
}

.sectionTitle {
  font-size: 3em;
  margin-top: 2em;
}

#aboutMeSection {
  position: relative;
  /* background-image: url("Design/gheryTwo.jpg"); */
  background-size: cover;
  padding-top: 1%;
  padding-top: var(--sectionPaddingTop);
  text-align: center;
  min-height: 500px;
  /* max-height: 700px; */
}

#aboutMeSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-image: url(/static/media/gheryTwo.b77cca1e.jpg);
  /* background-size: auto; */
  background-size: 2000px;
  background-attachment: fixed;
  z-index: -1;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
#aboutMeSection-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
}

#aboutMeIntro {
  font-size: 25px;
  text-align: left;
}

#aboutMeText {
  max-width: 100vw;
  display: inline-block;
}

.aboutMePara {
  font-size: 1.1em;
  /* display: table-cell; */
  text-align: left;
}

#aboutMeMatt {
  display: flex;
  flex-direction: row;
  width: 75vw;
  border-spacing: 10px;
  grid-gap: 3em;
  gap: 3em;
  padding: 2em;
  background-color: hsla(181, 42%, 60%, 0.83);
}
#imgContainerMatt {
  margin-top: -10px;
  min-width: 200px;
  max-width: 200px;
  min-height: 200px;
  max-height: 200px;
  display: table-cell;
}

#imgContainerCat {
  min-width: 200px;
  min-width: var(--catImgWidth);
  max-width: 200px;
  max-width: var(--catImgWidth);
  min-height: 200px;
  min-height: var(--catImgHeight);
  max-height: 200px;
  max-height: var(--catImgHeight);
  margin-left: 55%;
  margin-top: 5%;
  /* margin-bottom: 10%; */
}

#catImg {
  width: 200px;
  width: var(--catImgWidth);
  height: 200px;
  height: var(--catImgHeight);
  position: relative;
  display: inline-block;
  /* margin: 10px; */
  vertical-align: top;
}

#paraMattContainer {
  /* background-color: tomato; */
  display: table-cell;
  width: 900px;
  /* min-width: 600px;
  max-width: 600px;
  min-height: 800px;
  max-height: 800px; */
}

#mattImg {
  width: 100%;
  height: 100%;
}

#contactSection {
  text-align: center;
  padding-top: 1%;
  padding-top: var(--sectionPaddingTop);
  /* background-color: var(--thirdColor); */
  background-color: rgb(228, 227, 227);
  background-color: var(--lightGrey);
  color: black;
}

#contactSection a {
  color: black;
}

#contactTitle {
  margin-top: 1%;
}

#allFooterIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}

.footerIconContainer {
  padding: 10px;
  transition: all 1s;
}

.footerIconContainer:hover {
  background-color: #4a84f6;
  background-color: var(--secondColor);
  /* fill: var(--secondColor); */
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 1s;
}
.footerIconLink {
  color: black;
}

